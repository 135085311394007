@tailwind base;

*::-webkit-scrollbar {
  display: none;
}

body {
  background: #eef2f4;
  scroll-behavior: smooth;
}

@tailwind components;
@tailwind utilities;
