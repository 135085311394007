.bFeatures-bg {
  background-image: url(/src/assets/features-croped.png);
  background-repeat: no-repeat;
  background-position: right;
}

.slideShow {
  background-image: url(/src/assets/StairSidecopy.png);
}

.weekly-bg {
  background-image: url(/src/assets/dotsssss-removebg.png);
  background-repeat: no-repeat;
  background-position: left;
  background-size: 24rem;
}

.hero-text {
  font-size: clamp(3rem, 1rem + 10vw, 4.5rem);
  font-weight: 700;
  padding-bottom: 3px;
}

.weekly-image {
  max-width: 100%;
  min-height: 100%;
}

.about-bg {
  clip-path: polygon(0px 13%, 100% 0px, 100% 100%, 0px 96%);
}

.container {
  padding: 0 1rem;
  max-width: 100rem;
  margin: 0 auto;
}

.card {
  padding: 10rem 1rem;
}

@media (max-width: 40em) {
  .weekly-bg {
    background-image: none;
  }

  .bFeatures-bg {
    background-image: none;
  }

  .about-bg {
    clip-path: polygon(0px 8%, 100% 0px, 100% 100%, 0px 100%);
  }

  .contact-card {
    margin-top: 1rem;
  }
}

@media (max-width: 400px) {
  .about-bg {
    clip-path: polygon(0px 0, 100% 0px, 100% 100%, 0px 100%);
  }
}
